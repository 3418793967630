import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/nav-bar";
import styled from "styled-components";
import ApiCaller from "../core/Api/index";
import Bg from "../images/report/group-14.jpg";
import * as R from "ramda";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Pagination from "@material-ui/lab/Pagination";

const OSS = require("ali-oss");

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI5t8W7EcvrsExse7PzyP7",
  accessKeySecret: "JfXpSqGRAzFnDJuuwFAO0dgYJGfckF",
  bucket: "amssdata-freetrial",
  endPoint: "oss-cn-beijing.aliyuncs.com",
});

const Cover = ({ address }) => {
  const [state, setState] = useState("");

  useEffect(() => {
    const getImage = async address => {
      if (address) {
        return await client.signatureUrl(address);
      }
    };
    getImage(address).then(res => setState(res));
  }, []);
  return <div className="report-banner" style={{ backgroundImage: `url(${state})` }} />;
};

export default () => {
  const [firstPage, setFirstPage] = useState(null);
  const [allReposts, setAllReports] = useState([]);
  const [reports, setReports] = useState([]);
  const { t, i18n } = useTranslation();
  const { remove, splitEvery, compose } = R;

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (allReposts.length > 0) {
      setFirstPage(allReposts[page - 1][0]);
      const f = compose(splitEvery(3), remove(0, 1));
      const b = f(allReposts[page - 1]);
      setReports(b);
    }
  }, [page, allReposts]);

  useEffect(() => {
    ApiCaller.getResearchProjects()
      .then(res => res.filter(report => report.isFree === true))
      .then(res => {
        if (res && res.length > 0) {
          setAllReports(splitEvery(7, res));
        }
      });
  }, []);

  const downloadReport = async address => {
    const url = await client.signatureUrl(address);
    window.open(url, "Download");
  };

  return (
    <Layout>
      <NavBar main />
      <Container>
        <Wrap>
          <Banner bg={Bg}>
            {i18n.language === "zh" && (
              <h2 className="title">
                晤桥亚洲以专业角度为您提供 <br />
                最优质的城市分析报告
              </h2>
            )}
          </Banner>
          <Wrap2>
            <MainTitle>{t("research")}</MainTitle>
            <ReportFirstPage>
              {firstPage && <Cover address={firstPage.iconPic} />}
              <div className="report-detail">
                <h6 className="report-title">{firstPage && firstPage.title}</h6>
                <p className="report-subtitle">{firstPage && firstPage.time}</p>
                <div className="report-content" dangerouslySetInnerHTML={{ __html: firstPage && firstPage.detail }} />
                <p className="report-download" onClick={() => downloadReport(firstPage && firstPage.address)}>
                  &#9658; {t("download")}
                </p>
              </div>
            </ReportFirstPage>
            {reports &&
              reports.map((item, index) => {
                return (
                  <ReportGridWrap key={index}>
                    {item.map(report => (
                      <ReportGrid key={report._id}>
                        <Cover address={report.iconPic} />
                        <div className="report-detail">
                          <h6 className="report-title">{report.title}</h6>
                          <p className="report-subtitle">{report.time}</p>
                          <div className="report-content" dangerouslySetInnerHTML={{ __html: report.detail }} />
                          <p className="report-download" onClick={() => downloadReport(report.address)}>
                            {" "}
                            &#9658; {t("download")}
                          </p>
                        </div>
                      </ReportGrid>
                    ))}
                  </ReportGridWrap>
                );
              })}
            <PaginWrap>
              <Pagination count={allReposts.length} shape="rounded" page={page} onChange={handleChange} />
            </PaginWrap>
          </Wrap2>
        </Wrap>
      </Container>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaginWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .MuiPaginationItem-page.Mui-selected {
    background-color: #2f3852;
    color: #fff;
  }
  .MuiPaginationItem-page:hover,
  .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #2f3852;
    color: #fff;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  margin-top: 70px;
  display: flex;
  justify-content: center;
`;
const Wrap = styled.div`
  width: 100%;
  max-width: 1360px;
  padding: 30px 70px;
  // background-color: #f7f7f7;
`;

const Wrap2 = styled.div`
  margin-top: 25px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 25px 70px;
`;

const Banner = styled.div`
  width: 100%;
  height: 570px;
  background-image: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;

const MainTitle = styled.h1`
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f3852;
  padding: 15px 15px 15px 0;
`;

const ReportFirstPage = styled.div`
  width: 100%;
  height: 440px;
  padding: 34px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .report-banner {
    width: 293px;
    height: 100%;
    background: #f7f7f7 center;
    background-size: cover;
  }

  .report-detail {
    width: 450px;
  }
  .report-title {
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    color: #2f3852;
  }
  .report-subtitle {
    width: 188px;
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #2f3852;
  }
  .report-content {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #2f3852;
  }
  .report-download {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    cursor: pointer;
    color: #0987eb;
    margin-top: 35px;
  }
`;

const ReportGridWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
`;

const ReportGrid = styled.div`
  width: 380px;
  padding: 34px 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 40px;
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  .report-banner {
    min-width: 140px;
    height: 198px;
    margin-right: 10px;
    background: #f7f7f7 center;
    background-size: cover;
  }

  .report-detail {
  }
  .report-title {
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #2f3852;
  }
  .report-subtitle {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #2f3852;
  }
  .report-content {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #2f3852;
  }
  .report-download {
    position: absolute;
    font-size: 12px;
    line-height: 1.71;
    letter-spacing: normal;
    color: #0987eb;
    cursor: pointer;
    bottom: 10px;
  }
`;
